import http from "@/api/http.js";

//工具类封装-------------------------------------------------------------------------------------------------
//转化成 Key Value 格式的列表
function objectToKvpList(currObject) {
  let backList = [];
  if (currObject == null) return backList;
  let currObjList = Object.entries(currObject);
  for (let i = 0; i < currObjList.length; i++) {
    let currKvp = { Key: currObjList[i][0], Value: currObjList[i][1] };
    backList.push(currKvp);
  }
  return backList;
}

//转化成  值|文本 格式的列表
function objectToFormList(currObject) {
  let backList = [];
  if (currObject == null) return backList;
  let currObjList = Object.entries(currObject);
  for (let i = 0; i < currObjList.length; i++) {
    backList.push(currObjList[i][0] + "|" + currObjList[i][1]);
  }
  return backList;
}

//转化成 值|文本 格式的 Sting 文本
function objectToFormString(currObject) {
  if (currObject == null) return "";
  let currObjList = Object.entries(currObject);
  return currObjList[0][1] + "|" + currObjList[1][1];
}

//功能封装区域-------------------------------------------------------------------------------------------------
function kkDels(vue, currUrl, param) {
  vue
    .$confirm("您确认要删除选中的记录吗？", "提示", { type: "warning" })
    .then(() => {
      let currData = http.post(currUrl, param);
      currData.then(() => {
        vue.$message({ message: "删除成功", type: "success" });
        vue.initData();
      });
    });
}

//例子 let strObject = {
//       "teacher": ["2021082511330425905696|张老师_88877733"],
//       "grade": ["1629950672831|201701班", "1629950675223|201702班"]
//    };
//调用 this.utils.getGroupList(strObject,"grade");
function getGroupList(strObject, strKey) {
  let currObject = {};
  if (typeof strObject == "string") currObject = JSON.parse(strObject);
  else currObject = strObject;

  let backList = [];
  let strList = currObject[strKey];
  if (strList != null) {
    strList.forEach((item) => {
      backList.push(splitValue(item));
    });
  }
  return backList;
}

//从 String 中取
function getGroupKey(strObject, strKey) {
  let currObject = {};
  if (typeof strObject == "string") currObject = JSON.parse(strObject);
  else currObject = strObject;

  let backList = "";
  let strList = currObject[strKey];
  if (strList != null) {
    backList = splitKey(strList);
  }
  return backList;
}

//从 String 中取
function getGroupValue(strObject, strKey) {
  let currObject = {};
  if (typeof strObject == "string") currObject = JSON.parse(strObject);
  else currObject = strObject;

  let backList = "";
  let strList = currObject[strKey];
  if (strList != null) {
    backList = splitValue(strList);
  }
  return backList;
}

//分割 | 之后获取Key  例如
// 25905696|张老师_88877733  获取到 25905696
function splitKey(strValue) {
  if (strValue.indexOf("|") >= 0) {
    let currValue = strValue.split("|")[0];
    if (currValue.indexOf("_") >= 0) {
      return currValue.split("_")[0];
    } else {
      return currValue;
    }
  } else {
    return strValue;
  }
}

//分割 | 之后获取Value      例如:
// 25905696|张老师_88877733  获取到 张老师
function splitValue(strValue) {
  if (strValue.indexOf("|") >= 0) {
    let currValue = strValue.split("|")[1];
    if (currValue.indexOf("_") >= 0) {
      return currValue.split("_")[0];
    } else {
      return currValue;
    }
  } else {
    return strValue;
  }
}

//获取当前时间(年月日)
function getCurrDate() {
  let yy = new Date().getFullYear();
  let mm = new Date().getMonth() + 1;
  let dd = new Date().getDate();
  return yy + "-" + mm + "-" + dd;
}

//获取当前时间(时分秒)
function getCurrTime() {
  let hh = new Date().getHours();
  let mf =
    new Date().getMinutes() < 10
      ? "0" + new Date().getMinutes()
      : new Date().getMinutes();
  let ss =
    new Date().getSeconds() < 10
      ? "0" + new Date().getSeconds()
      : new Date().getSeconds();
  return hh + ":" + mf + ":" + ss;
}

//获取当前时间(年月日时分秒)
function getCurrAllTime() {
  let yy = new Date().getFullYear();
  let mm = new Date().getMonth() + 1;
  let dd = new Date().getDate();
  let hh = new Date().getHours();
  let mf =
    new Date().getMinutes() < 10
      ? "0" + new Date().getMinutes()
      : new Date().getMinutes();
  let ss =
    new Date().getSeconds() < 10
      ? "0" + new Date().getSeconds()
      : new Date().getSeconds();
  return yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
}

// 获取当前星期
function getCurrDay(date) {
  var dayjs = require("dayjs");
  var newDate = dayjs(date).format("YYYY/MM/DD");
  var week = new Date(newDate).getDay();
  switch (week) {
    case 0:
      return (week = "星期天");
    case 1:
      return (week = "星期一");
    case 2:
      return (week = "星期二");
    case 3:
      return (week = "星期三");
    case 4:
      return (week = "星期四");
    case 5:
      return (week = "星期五");
    case 6:
      return (week = "星期六");
  }
}

// 数组去重(普通数组格式)
function uniqueArr(array) {
  return Array.from(new Set(array));
}

// 数组去重(Json格式)
function uniqueJson(arr) {
  const res = new Map();
  return arr.filter((arr) => !res.has(arr.key) && res.set(arr.key, 1));
}

export default {
  getGroupList,
  getGroupKey,
  getGroupValue,
  splitKey,
  splitValue,
  objectToKvpList,
  objectToFormList,
  objectToFormString,
  kkDels,
  getCurrDate,
  getCurrTime,
  getCurrAllTime,
  getCurrDay,
  uniqueArr,
  uniqueJson,
};
