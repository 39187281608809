import http from "@/api/http.js";

//初始化赋值Vue
let _vue = null;
function init(vue) {
    _vue = vue;
}

async function getSqlSetting(pageKey, dataType) {
    
    let tryGet = _vue.$root.sqlSetting[pageKey + "_" + dataType];
    if (tryGet != null) {
        return tryGet;
    }
    else {
        //开启下面的注释，可以检测是否有反复调用类型(类型不存在)
        //_vue.$message({ message: pageKey+" _ "+ dataType, type: "error" });      
        let para = { pageKey: pageKey };
        let res = await http.get("api/SqlSetting/GetDataByPageKey", para);
        if (res.success) {
            for (var key in res.data) {
                _vue.$root.sqlSetting[key] = JSON.parse(res.data[key]);
            }
            return _vue.$root.sqlSetting[pageKey + "_" + dataType];
        }
    }
}

async function getDictionaryGroup(optionsMarkList, postOptionsSql) {
    //先检查所有需要的参数
    let getOptionsMarkList = [];
    optionsMarkList.forEach(item => {
        if (_vue.$root.dictionaryGroup[item] == null) {
            getOptionsMarkList.push(item);
        }
    });
    //如果缺少参数，则将它们串连在一起，统一请求
    if (getOptionsMarkList.length > 0) {        
        let para = {
            optionsMark: getOptionsMarkList.join("|"),
            optionsSql: postOptionsSql,
        };
        let res = await http.post("api/SqlSetting/GetRuleOptions", para);
        if (res.success) {
            for (var key in res.data) {
                _vue.$root.dictionaryGroup[key] = res.data[key];
            }
        }
    }
    //返回对应的字典项
    let backList = {};
    optionsMarkList.forEach(item => {
        backList[item] = _vue.$root.dictionaryGroup[item];
    });
    return backList;
}

function getOptionsMarkList(formSetting) {
    //通过 markOptions 获取下拉的数据
    let currMarkOptions = [];
    formSetting.forEach((kvp) => {
        if (kvp.isShow) {
            if (kvp.markOptions != null && kvp.markOptions != "") {
                currMarkOptions.push(kvp.markOptions);
            }
        }
    });
    return currMarkOptions;
}

async function getDictionaryTreeData(cmdKey) {
    if (_vue.$root.dictionary[cmdKey] != null) {      
        return JSON.parse(_vue.$root.dictionary[cmdKey]);
    }
    else {      
        let para = { cmdKey: cmdKey };
        let res = await http.get("api/Dictionary/GetEntityByMark", para);
        if (res.success) {
            _vue.$root.dictionary[cmdKey]=res.data.treeData;
            return JSON.parse(res.data.treeData);
        }
        else {
            _vue.$message({ message: res.msg, type: "error" });
        }
    }
}

export default { init, getDictionaryTreeData, getOptionsMarkList, getDictionaryGroup, getSqlSetting }


