import Cookies from "js-cookie";
import http from "@/api/http.js";


//Cookies封装-------------------------------------------------------------------------------------------------
function getUser() {
    let currUser = kkGet("userCookies");
    if (currUser == null) {
        let path = window.location.href.split("/"); //分割url       
        window.location.href = path[0] + "//" + path[2] + "/#/login";
    }
    return currUser;
}

function getRole() {
    let currRole = kkGet("role");
    if (currRole == null) {
        let path = window.location.href.split("/"); //分割url       
        window.location.href = path[0] + "//" + path[2] + "/#/login";
    }
    return currRole;
}

function kkSet(strKey, strValue) {
    let currCookie = Cookies.set(strKey, JSON.stringify(strValue), { expires: 7 });
    return currCookie;
}

function kkSetDomain(strKey, strValue) {
    let currDomain = http.cookieDomains;
    if (window.location.href.indexOf("localhost") != -1) {
        currDomain = "localhost";
    }
    let currCookie = Cookies.set(strKey, JSON.stringify(strValue), { expires: 7, domain: currDomain });
    return currCookie;
}

function kkDelete(strKey) {
    Cookies.remove(strKey);
}

function kkGet(strKey) {
    if (Cookies.get(strKey) == null) return null;
    let currCookie = JSON.parse(Cookies.get(strKey));
    return currCookie;
}

export default { getUser, kkSet, kkSetDomain, kkGet, kkDelete, getRole }
