import http from "@/api/http.js";

//初始化赋值Vue
let _vue = null;
function init(vue) {
    _vue = vue;
}

//封装，获取Ref控件
function getRef(that, field) {  
    return that.$refs[field][0];
}

//初始化配置控件参数
function fillFormSetting(that) {
    that.formSetting2.forEach(item => {
        if (item.label != null && item.label != "")
            this.getRef(that, item.field).label = item.label;

        if (item.info != null && item.info != "")
            this.getRef(that, item.field).info = item.info;

        if (item.options != null && item.options != "")
            this.getRef(that, item.field).options = item.options;

        if (item.isValidate != null && item.isValidate != "")
            this.getRef(that, item.field).isValidate = item.isValidate;
    });
}

//获取表单的值
function getFormValue(that) {
    let backValue = {};
    //获取表单的值
    that.formSetting2.forEach(item => {
        _vue.$set(backValue, item.field, this.getRef(that, item.field).value);
    });
    //转化 *|Kvp 格式化值
    that.formSetting2.forEach(item => {
        if (item.field.indexOf("|Kvp") >= 0) {
            if (item.type == "checkbox" || item.type == "cascader2") {
                _vue.$set(backValue, item.field.replace("|Kvp", "") + "Key", JSON.stringify(this.getRef(that, item.field).selectValue));
                _vue.$set(backValue, item.field.replace("|Kvp", "") + "Value", JSON.stringify(this.getRef(that, item.field).selectText));
            }
            else {
                _vue.$set(backValue, item.field.replace("|Kvp", "") + "Key", this.getRef(that, item.field).selectValue);
                _vue.$set(backValue, item.field.replace("|Kvp", "") + "Value", this.getRef(that, item.field).selectText);
            }
            _vue.$delete(backValue, item.field);
        }
    });
    return backValue;
}

//设置表单的值
function setFormValue(that, currPost) {
    let formData = {};
    //统一首字母转小写
    for (let item in currPost) {
        _vue.$set(formData, item.substring(0, 1).toLowerCase() + item.substring(1), currPost[item]);
    }
    //给表单赋值
    that.formSetting2.forEach(item => {
        if (item.field.indexOf("|Kvp") >= 0)
            this.setRefValue(that, item, formData[item.field.replace("|Kvp", "") + "Key"]);
        else
            this.setRefValue(that, item, formData[item.field]);
    });
}

//单独设置Ref控件的值
function setRefValue(that, item, formFieldValue) {
    if (formFieldValue != null) {
        if (item.type == "checkbox" || item.type == "cascader2")
            this.getRef(that, item.field).value = this.getObject(formFieldValue);
        else
            this.getRef(that, item.field).value = formFieldValue;
    }
}

//检查格式，转化为对象
function getObject(strObject) {
    let currObject = {};
    if (typeof strObject == "string")
        currObject = JSON.parse(strObject);
    else
        currObject = strObject;
    return currObject;
}

async function loadOptions(that) {
    //获取下拉选项的值
    let optionsMarkList = _vue.table.getOptionsMarkList(that.formSetting2);
    let formOptions = await _vue.table.getDictionaryGroup(optionsMarkList, that.postOptionsSql);
    that.formSetting2.forEach(item => {
        if (item.markOptions != null && item.markOptions != "") {
            this.getRef(that, item.field).options = this.getObject(formOptions[item.markOptions]);
        }
    });
}

function saveToSql(that,formData, controllerName) {   
    if (that.id == null || that.id == "0") {
        let currApi = http.post("api/" + controllerName + "/add", formData);
        currApi.then((res) => {
            if (res.success) {
                _vue.$message({ message: "添加成功", type: "success" });
                that.$emit('close', false);
            }
        });
    }
    else {
        _vue.$set(formData, "id", that.id); //补充Id        
        let currApi = http.post("api/" + controllerName + "/update", formData);
        currApi.then((res) => {
            if (res.success) {
                _vue.$message({ message: "修改成功", type: "success" });
                that.$emit('close', false);
            }
        });
    }
}


export default { init, getRef, fillFormSetting, getFormValue, setFormValue, setRefValue, getObject, loadOptions, saveToSql }


