<template>
  <div id="app">
    <div id="nav">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
  components: {},
  mounted() { }
};
</script>

<style>
html,
body,
#app,
#nav {
  width: 100%;
  height: 100%;
  padding: 0px !important;
  margin: 0px !important;
  font-family: "PingFang SC", Arial, "Microsoft YaHei", sans-serif;
  overflow-x: hidden;
  font-size: 14px;
}
</style>
