import axios from "axios";

//初始化赋值Vue
let _vue = null;
function init(vue) {
  _vue = vue;
}

axios.defaults.timeout = 60*60*1000;
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
//axios.defaults.baseURL = "http://localhost:9611/";
// axios.defaults.baseURL = "http://119.3.159.23:9601/";
axios.defaults.baseURL = "http://192.168.123.115:9601/";

//是否开发阶段
const isDevelopment = true;
//const cookieDomains = ".micro.osce.cc"; //跨站Cookies标记
const cookieDomains = "192.168.123.115"; //跨站Cookies标记

//获取当前页面的 Url 前缀地址
function getUrlRoot() {
  let path = window.location.href.split("/"); //分割url
  let currUrl = path[0] + "//" + path[2] + "/#/";
  return currUrl;
}

//获取调用主域名
function getBaseUrl() {
  return axios.defaults.baseURL;
}

//数据请求区域-------------------------------------------------------------------------------------------------
function post(currUrl, params) {
  // console.log("---post "+currUrl+" param="+JSON.stringify(params))
  return new Promise((resolve) => {
    axios
      .post(currUrl, params)
      .then(
        (response) => {
          resolve(response.data);
        },
        (err) => {
          _vue.$message({ message: err, type: "error" });
        }
      )
      .catch((error) => {
        _vue.$message({ message: error, type: "error" });
      });
  });
}

function get(currUrl, param) {
  // console.log("---get "+currUrl+" param="+JSON.stringify(param))
  return new Promise((resolve) => {
    axios
      .get(currUrl, { params: param })
      .then(
        (response) => {
          resolve(response.data);
        },
        (err) => {
          _vue.$message({ message: err, type: "error" });
        }
      )
      .catch((error) => {
        _vue.$message({ message: error, type: "error" });
      });
  });
}

function postExcel(currUrl, param, data) {
  return new Promise((resolve) => {
    axios
      .post(currUrl, data, {
        params: param,
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(
        (response) => {
          resolve(response.data);
        },
        (err) => {
          _vue.$message({ message: err, type: "error" });
        }
      )
      .catch((error) => {
        _vue.$message({ message: error, type: "error" });
      });
  });
}

//Post提交数据到Url
function postToUrl(url, obj) {
  if (url.indexOf("http") < 0) {
    url = axios.defaults.baseURL + url;
  }
  var temp_form = document.createElement("form");
  temp_form.action = url;
  temp_form.target = "_blank";
  temp_form.method = "post";
  temp_form.style.display = "none";

  //添加参数
  for (var item in obj) {
    var opt = document.createElement("textarea");
    opt.name = item;
    opt.value = obj[item];
    temp_form.appendChild(opt);
  }
  document.body.appendChild(temp_form);
  //提交数据
  temp_form.submit();
  window.close(); //关闭窗口
}

export default {
  isDevelopment,
  init,
  getUrlRoot,
  getBaseUrl,
  get,
  post,
  postExcel,
  postToUrl,
  cookieDomains,
};
