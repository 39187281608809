import http from "@/api/http.js";
import utils from "@/api/utils.js";
//SqlSetting相关方法封装

//初始化赋值Vue
let _vue = null
function init(vue) {
    _vue = vue;
}

//表单结构------------------------------------------------------------------------
function form_Input(title, field, value, info, isValidate) {
    return {
        type: "input",
        field: field,
        title: title,
        value: value,
        info: info,
        validate: this.form_validate(isValidate, title),
        props: {
            placeholder: "请输入" + title,
            clearable: true
        }
    };
}

function form_password(title, field, value, info, isValidate) {
    return {
        type: "input",
        field: field,
        title: title,
        value: value,
        info: info,
        validate: this.form_validate(isValidate, title),
        props: {
            type: "password",
            clearable: true,
            showPassword: true
        }
    };
}

function form_number(title, field, value, info, isValidate) {
    return {
        type: "number",
        field: field,
        title: title,
        value: value,
        info: info,
        validate: this.form_validate(isValidate, title),
        props: {
            placeholder: "请输入" + title,
            clearable: true
        }
    }
}

function form_cascader(title, field, info, isValidate, options,textRule) {
    let currOptions = [{ "label": "未绑定数据", value: "0|未绑定数据" }];
    if (options != null) {
        currOptions = options;
    }

    let currCheck = false;
    let dicRule = this.getDicTextRule(textRule);
    if (dicRule.check != null) {
        currCheck = true;
    }

    return {
        type: "cascader",
        field: field,
        title: title,
        info: info,
        validate: this.form_validateOptions(isValidate, title, "string"),
        props: {
            options: currOptions,
            props: {
                emitPath: false,
                checkStrictly: currCheck,
            },
            clearable: true,
            filterable: true
        }
    }
}

function form_cascader2(title, field, info, isValidate, options) {
    let currOptions = [{ "label": "未绑定数据", value: "0|未绑定数据" }];
    if (options != null) {
        currOptions = options;
    }
    return {
        type: "cascader",
        field: field,
        title: title,
        info: info,
        validate: this.form_validateOptions(isValidate, title, "array"),
        props: {
            options: currOptions,
            props: {
                multiple: true,
                checkStrictly: true,
                emitPath: false
            },
            clearable: true,
            filterable: true
        }
    }
}

function form_textarea(title, field, value, info, isValidate) {
    return {
        type: "input",
        field: field,
        title: title,
        value: value,
        info: info,
        validate: this.form_validate(isValidate, title),
        props: {
            placeholder: "请输入" + title,
            type: "textarea",
            "rows": 3
        }
    }
}

function form_radio(title, field, value, info, isValidate, options) {
    let currOptions = [{ "label": "未绑定数据", value: "0|未绑定数据" }];
    if (options != null) {
        currOptions = options;
    }
    return {
        type: "radio",
        field: field,
        title: title,
        value: value,
        info: info,
        validate: this.form_validateOptions(isValidate, title, "string"),
        options: currOptions
    }
}

function form_checkbox(title, field, value, info, isValidate, options) {
    let currOptions = [{ "label": "未绑定数据", value: "0|未绑定数据" }];
    if (options != null) {
        currOptions = options;
    }
    return {
        type: "checkbox",
        field: field,
        title: title,
        value: [value],
        info: info,
        validate: this.form_validateOptions(isValidate, title, "array"),
        options: currOptions
    }
}

function form_switch(title, field, info, isValidate) {
    return {
        type: "switch",
        field: field,
        title: title,
        info: info,
        validate: this.form_validate(isValidate, title),
    }
}

function form_timePicker(title, field, value, info, isValidate) {
    return {
        type: "timePicker",
        field: field,
        title: title,
        value: value,
        info: info,
        validate: this.form_validate(isValidate, title),
    }
}

function form_datePicker(title, field, value, info, isValidate) {
    if (value == "") {
        value = utils.getCurrDate();
    }
    return {
        type: "datePicker",
        field: field,
        title: title,
        value: value,
        info: info,
        validate: this.form_validate(isValidate, title),
        props: {
            type: "date"
        }
    }
}

function form_upload(title, field, value, info, textRule) {
    let currLimit = 1;
    let dicRule = this.getDicTextRule(textRule);
    if (dicRule.limit != null) {
        currLimit = parseInt(dicRule.limit);
    }
    return {
        type: "upload",
        field: field,
        title: title,
        info: info,
        value: value,
        props:
        {
            limit: currLimit,
            type: "select",
            uploadType: "image",
            action: http.getBaseUrl() + "api/Upload/PostUpload",
            name: "postFile",
            multiple: true,
            accept: "image/*",
            onSuccess: "[[FORM-CREATE-PREFIX-function(e,t){t.url=e.data}-FORM-CREATE-SUFFIX]]"
        }
    };
}

function form_upload2(title, field, value, info, textRule) {
    let currLimit = 1;
    let dicRule = this.getDicTextRule(textRule);
    if (dicRule.limit != null) {
        currLimit = parseInt(dicRule.limit);
    }
    return {
        type: "upload",
        field: field,
        title: title,
        info: info,
        value: value,
        props: {
            limit: currLimit,
            type: "select",
            uploadType: "file",
            action: http.getBaseUrl() + "api/Upload/PostUpload",
            name: "postFile",
            multiple: true,
            onSuccess: "[[FORM-CREATE-PREFIX-function(e,t){t.url=e.data}-FORM-CREATE-SUFFIX]]"
        }
    }
}

//验证表单是否为空------------------------------------------------------------------------
function form_validate(isValidate, title) {
    let currValidate = [];
    let setValidate = {
        trigger: "blur",
        required: true,
        message: title + "不能为空！"
    };
    if (isValidate) {
        currValidate.push(setValidate);
    }
    return currValidate;
}

function form_validateOptions(isValidate, title, type) {
    let currValidate = [];
    let setValidate = {
        trigger: "blur",
        mode: "required",
        required: true,
        message: "请选择" + title,
        type: type
    };
    if (isValidate) {
        currValidate.push(setValidate);
    }
    return currValidate;
}

//获取对冲规则
function getDicTextRule(textRule) {
    if (textRule != "") {
        let dicList = {};
        let strArr = textRule.split("|");
        for (let i = 0; i < strArr.length; i++) {
            if (strArr[i] != "" && strArr[i].indexOf("=") >= 0) {
                let strText = strArr[i].split("=");
                _vue.$set(dicList, strText[0], strText[1]);
            }
        }
        return dicList;
    }
    else
        return {};
}

function fillRule(kvp, rule) {
    //控件类型判断
    if (kvp.type == "input") {
        rule.push(this.form_Input(kvp.label, kvp.field, kvp.defaultValue, kvp.info, kvp.isValidate));
    }
    if (kvp.type == "password") {
        rule.push(this.form_password(kvp.label, kvp.field, kvp.defaultValue, kvp.info, kvp.isValidate));
    }
    if (kvp.type == "number") {
        rule.push(this.form_number(kvp.label, kvp.field, kvp.defaultValue, kvp.info, kvp.isValidate));
    }
    if (kvp.type == "cascader") {
        rule.push(this.form_cascader(kvp.label, kvp.field, kvp.info, kvp.isValidate, kvp.options, kvp.textRule));
    }
    if (kvp.type == "cascader2") {
        rule.push(this.form_cascader2(kvp.label, kvp.field, kvp.info, kvp.isValidate, kvp.options));
    }
    if (kvp.type == "textarea") {
        rule.push(this.form_textarea(kvp.label, kvp.field, kvp.defaultValue, kvp.info, kvp.isValidate));
    }
    if (kvp.type == "radio") {
        rule.push(this.form_radio(kvp.label, kvp.field, kvp.defaultValue, kvp.info, kvp.isValidate, kvp.options));
    }
    if (kvp.type == "checkbox") {
        rule.push(this.form_checkbox(kvp.label, kvp.field, kvp.defaultValue, kvp.info, kvp.isValidate, kvp.options));
    }
    if (kvp.type == "switch") {
        rule.push(this.form_switch(kvp.label, kvp.field, kvp.info, kvp.isValidate));
    }
    if (kvp.type == "timePicker") {
        rule.push(this.form_timePicker(kvp.label, kvp.field, kvp.defaultValue, kvp.info, kvp.isValidate));
    }
    if (kvp.type == "datePicker") {
        rule.push(this.form_datePicker(kvp.label, kvp.field, kvp.defaultValue, kvp.info, kvp.isValidate));
    }
    if (kvp.type == "upload") {
        rule.push(this.form_upload(kvp.label, kvp.field, kvp.defaultValue, kvp.info, kvp.textRule));
    }
    if (kvp.type == "upload2") {
        rule.push(this.form_upload2(kvp.label, kvp.field, kvp.defaultValue, kvp.info, kvp.textRule));
    }
}

//数据保存之前格式化处理
function saveDataFormat(formSetting, formData) {
    formSetting.forEach((kvp) => {
        if (kvp.isShow) {
            if (kvp.field.indexOf("|Kvp") >= 0 && formData[kvp.field] != "") {
                if (kvp.type == "checkbox" || kvp.type == "cascader2") {
                    let currList = formData[kvp.field];
                    let strKey = "";
                    let strValue = "";
                    for (let i = 0; i < currList.length; i++) {
                        let currValue = currList[i].split("|");
                        strKey += (strKey == "" ? "" : "|") + currValue[0];
                        strValue += (strValue == "" ? "" : "|") + currValue[1];
                    }
                    _vue.$set(formData, kvp.field.replace("|Kvp", "") + "Key", strKey);
                    _vue.$set(formData, kvp.field.replace("|Kvp", "") + "Value", strValue);
                    _vue.$delete(formData, kvp.field);
                }
                else {
                    let currValue = formData[kvp.field].split("|");
                    _vue.$set(formData, kvp.field.replace("|Kvp", "") + "Key", currValue[0]);
                    _vue.$set(formData, kvp.field.replace("|Kvp", "") + "Value", currValue[1]);
                    _vue.$delete(formData, kvp.field);
                }
            }
        }
    });
}

//数据赋值之前格式化处理
function loadDataFormat(formSetting, row) {
    formSetting.forEach((kvp) => {
        if (kvp.isShow) {            
            //首字母转小写           
            kvp.field = kvp.field.substring(0, 1).toLowerCase() + kvp.field.substring(1);
            
            if (kvp.field.indexOf("|Kvp") >= 0) {
                //console.log(JSON.stringify(kvp.field)+" "+JSON.stringify(row))
                //console.log(row)
                //console.log(row[kvp.field])
                let fieldKeyData = row[kvp.field.replace("|Kvp", "") + "Key"];
                let fieldValueData = row[kvp.field.replace("|Kvp", "") + "Value"];
                let currList = [];
                if (kvp.type == "checkbox" || kvp.type == "cascader2") {
                    let arrKey = fieldKeyData.split("|");
                    let arrValue = fieldValueData.split("|");
                    for (let i = 0; i < arrKey.length; i++) {
                        currList.push(arrKey[i] + "|" + arrValue[i]);
                    }
                    _vue.$set(row, kvp.field, currList);
                }
                else {
                    _vue.$set(row, kvp.field, fieldKeyData + "|" + fieldValueData);

                }
                _vue.$delete(row, kvp.field.replace("|Kvp", "") + "Key");
                _vue.$delete(row, kvp.field.replace("|Kvp", "") + "Value");
            }
            //存储的时候，将数组转化成了文本，需要反转化
            if (kvp.type == "upload" || kvp.type == "upload2" || kvp.type == "checkbox" || kvp.type == "cascader2") {
                if (row[kvp.field] != null && row[kvp.field].indexOf("[") >= 0 && row[kvp.field].indexOf("]") >= 0) {
                    row[kvp.field] = JSON.parse(row[kvp.field]);
                }
            }
        }
    });
}

//填充规则下拉选项
function fillRuleOptions(kvp, formOptions) {
    if (kvp.markOptions != "") {
        let currValue = formOptions[kvp.markOptions];

        //如果 cmd=id 则只显示Id
        let dicRule = this.getDicTextRule(kvp.textRule);
        if (dicRule.cmd != null && dicRule.cmd == "id") {
            currValue = this.getIdOnly(currValue);
        }
        if (dicRule.cmd != null && dicRule.cmd == "level") {
            currValue = this.getLevelId(currValue);
        }
        if (dicRule.cmd != null && dicRule.cmd == "group") {
            currValue = this.getLevelGroup(currValue);
        }
        kvp.options = JSON.parse(currValue);
    }
}

//重新配置下拉项的值，展示Id字段
function getIdOnly(currValue) {
    let dataList = JSON.parse(currValue);
    this.setIdOnly(dataList);
    return JSON.stringify(dataList);
}

//递归操作
function setIdOnly(dataList) {
    dataList.forEach(res => {
        res.value = res.value.split("|")[0];
        if (res.children && res.children.length > 0) {
            this.setIdOnly(res.children);
        }
    });
}

//重新配置下拉项的值，获取多级节点Id  以 _ 拼接
function getLevelId(dataList) {
    let postDataList = JSON.parse(dataList);
    this.setLevelId(postDataList, "");
    return JSON.stringify(postDataList);
}

//递归操作
function setLevelId(dataList, rootValue) {
    dataList.forEach(res => {
        let resValue = res.value.split("|")[0];
        res.value = rootValue + (rootValue == "" ? "" : "_") + resValue;
        if (res.children && res.children.length > 0) {
            this.setLevelId(res.children, res.value);
        }
    });
}

//重新配置下拉项的值，获取多级节点Id  以 _ 拼接
function getLevelGroup(dataList) {
    let postDataList = JSON.parse(dataList);
    this.setLevelGroup(postDataList, "");
    return JSON.stringify(postDataList);
}

//递归操作
function setLevelGroup(dataList, rootValue) {
    dataList.forEach(res => {
        res.value = rootValue + (rootValue == "" ? "" : "_") + res.value;
        if (res.children && res.children.length > 0) {
            this.setLevelGroup(res.children, res.value);
        }
    });
}

export default {
    init, form_Input, form_password, form_number, form_cascader, form_cascader2, form_textarea, form_radio, form_checkbox, form_switch, form_timePicker,
    form_datePicker, form_upload, form_upload2, form_validate, form_validateOptions, getDicTextRule, setIdOnly, setLevelId, getIdOnly, getLevelId, getLevelGroup, setLevelGroup, fillRule, saveDataFormat, loadDataFormat, fillRuleOptions
}