import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import ElementUI from "element-ui";
import formCreate from "@form-create/element-ui";
import dragTreeTable from "drag-tree-table";
import "element-ui/lib/theme-chalk/index.css";
import "amfe-flexible";
import "../src/assets/css/global.css";
import less from "less";
import "default-passive-events";
import "../src/assets/css/iconfont.css";
import moment from "moment";
import VCharts from "v-charts";
import vueEsign from "vue-esign";

import {
  RadioGroup,
  Radio,
  Button,
  Icon,
  Checkbox,
  CheckboxGroup,
  Notify,
  Tab,
  Tabs,
  Grid, GridItem,
  Dialog,
  TreeSelect,
  Uploader,
  Loading,
  Popup,
  Switch,
  Field,
  Slider,
  Image,
  NavBar,
  Col,
  Row,
  Overlay,
  Stepper,
} from "vant";

axios.defaults.baseURL = "https://vl.osce.cc";
// axios.defaults.withCredentials = true;
Vue.prototype.$bus = new Vue();

Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Button);
Vue.use(Grid);
Vue.use(Col);
Vue.use(Row);
Vue.use(Loading);
Vue.use(GridItem);
Vue.use(Image);
Vue.use(Icon);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Notify);
Vue.use(Overlay);
Vue.use(Tab);
Vue.use(NavBar);
Vue.use(Tabs);
Vue.use(Dialog);
Vue.use(TreeSelect);
Vue.use(Uploader);
Vue.use(Popup);
Vue.use(Switch);
Vue.use(Field);
Vue.use(Slider);
Vue.use(Stepper);

Vue.use(vueEsign);
Vue.use(VCharts);
Vue.filter("dataformat", (v) => {
  return moment(v).format("YYYY-MM-DD");
});
Vue.use(less);
// Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.use(ElementUI);
Vue.use(formCreate);
Vue.use(dragTreeTable);

var vue = new Vue({
  router,
  data: function () {
    return {
      //系统参数
      navgition: [],
      // config: {},
      sqlSetting: {},
      dictionaryGroup: {},
      dictionary: {},
    };
  },
  store,
  render: (h) => h(App),
});

//扩展接口封装
import http from "@/api/http.js";
import utils from "@/api/utils.js";
import cookies from "@/api/cookies.js";
// import config from "@/api/config.js";
import sqlsetting from "@/api/sqlsetting.js";
import table from "@/api/table.js";
import unit from "@/api/unit.js";
Vue.prototype.http = http;
Vue.prototype.http.init(vue);
Vue.prototype.utils = utils;
Vue.prototype.cookies = cookies;
// Vue.prototype.config = config;
// Vue.prototype.config.init(vue);
Vue.prototype.sqlsetting = sqlsetting;
Vue.prototype.sqlsetting.init(vue);
Vue.prototype.table = table;
Vue.prototype.table.init(vue);
Vue.prototype.unit = unit;
Vue.prototype.unit.init(vue);

//Vue开始执行页面代码
vue.$mount("#app");
