import Vue from "vue";
import VueRouter from "vue-router";
import login from "../views/mobile/login.vue";

Vue.use(VueRouter);

const routes = [
  // 登录页面
  {
    path: "/",
    name: "login",
    component: login,
  },
  //测试登录页面
  {
    path: "/tlogin",
    name: "tlogin",
    component: () => import("../views/mobile/login2.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/mobile/test.vue"),
  },
  //个人中心
  {
    path: "/personal",
    name: "personal",
    component: () => import("../views/mobile/personal.vue"),
  },
  //老师个人中心
  {
    path: "/teacherpersonal",
    name: "teacherpersonal",
    component: () => import("../views/mobile/teacher/personal.vue"),
  },
  // 手机端页面
  // 登录
  {
    path: "/home",
    name: "home",
    component: () => import("../views/mobile/index.vue"),
  },
  // 老师手机端页面
  // 首页
  {
    path: "/teacherhome",
    name: "teacherhome",
    component: () => import("../views/mobile/teacher/index.vue"),
  },
  // 项目学习
  {
    path: "/demoStudy",
    name: "demoStudy",
    component: () => import("../views/mobile/demoStudy.vue"),
  },
  // 项目首页
  {
    path: "/demoIndex",
    name: "demoIndex",
    component: () => import("../views/mobile/demoIndex.vue"),
  },
  // 项目学习
  {
    path: "/projectStudy",
    name: "projectStudy",
    component: () => import("../views/mobile/work/projectStudy.vue"),
  },
  // 老师视频作业
  {
    path: "/teacherwork",
    name: "teacherwork",
    component: () => import("../views/mobile/teacher/taskList.vue"),
  },
    // 老师视频作业
    {
      path: "/teacherworkchange",
      name: "teacherworkchange",
      component: () => import("../views/mobile/teacher/workchange.vue"),
    },
  // 老师批改作业列表
  {
    path: "/teacherworklist",
    name: "teacherworklist",
    component: () => import("../views/mobile/teacher/workList.vue"),
  },
  // 老师批改作业
  {
    path: "/correctwork",
    name: "correctwork",
    component: () => import("../views/mobile/teacher/work.vue"),
  },
  //查看批改作业记录
  {
    path: "/correctworkLog",
    name: "correctworkLog",
    component: () => import("../views/mobile/teacher/workLog.vue"),
  },
  // 学生视频作业
  {
    path: "/work",
    name: "work",
    component: () => import("../views/mobile/work/work.vue"),
  },
  //学生作业详情页
  {
    path: "/work2",
    name: "work2",
    component: () => import("../views/mobile/work/work2.vue"),
  },
  {
    path: "/workLog",
    name: "workLog",
    component: () => import("../views/mobile/work/workLog.vue"),
  },
  {
    path: "/work4",
    name: "work4",
    component: () => import("../views/mobile/work/work4.vue"),
  },
  // 老师视频作业
  // {
  //     path: "/taskList",
  //     name: "taskList",
  //     component: () => import("../views/mobile/work/taskList.vue"),
  // },
  // 视频学习
  {
    path: "/videoStudy",
    name: "videoStudy",
    component: () => import("../views/mobile/videoSection/index.vue"),
  },
  {
    path: "/studyContent",
    name: "studyContent",
    component: () => import("../views/mobile/videoSection/studyContent.vue"),
  },
  // 通过性自我测验
  {
    path: "/testStudy",
    name: "testStudy",
    component: () => import("../views/mobile/testStudy.vue"),
  },
  // 实训引导
  {
    path: "/guideStudy",
    name: "guideStudy",
    component: () => import("../views/mobile/guideStudy.vue"),
  },
  // 操作评价
  // 选择评分表
  {
    path: "/evaluateStudy",
    name: "evaluateStudy",
    component: () =>
      import("../views/mobile/evaluateOperate/evaluateStudy.vue"),
  },
  //评分表页面
  {
    path: "/evaluateTable",
    name: "evaluateTable",
    component: () =>
      import("../views/mobile/evaluateOperate/evaluateTable.vue"),
  },
  //评分表页面
  {
    path: "/evaluateContent",
    name: "evaluateContent",
    component: () =>
      import("../views/mobile/evaluateOperate/evaluateContent.vue"),
  },
  // 评价记录
  {
    path: "/evaluateRecord",
    name: "evaluateRecord",
    component: () => import("../views/mobile/evaluateRecord/index.vue"),
  },
  // 评价记录详情
  {
    path: "/recordInfo",
    name: "recordInfo",
    component: () => import("../views/mobile/evaluateRecord/info.vue"),
  },

  // 横屏demo
  {
    path: "/testVideo",
    name: "testVideo",
    component: () => import("../views/mobile/testVideo.vue"),
  },
  // 老师操作评价
  // 选择评分表
  {
    path: "/teaevaluateStudy",
    name: "teaevaluateStudy",
    component: () => import("../views/mobile/teacher/evaluateStudy.vue"),
  },
  //老师评分表页面
  {
    path: "/teaevaluateTable",
    name: "teaevaluateTable",
    component: () => import("../views/mobile/teacher/evaluateTable.vue"),
  },
  //老师评分表页面
  {
    path: "/teaevaluateContent",
    name: "teaevaluateContent",
    component: () => import("../views/mobile/teacher/evaluateContent.vue"),
  },
  // 老师评价记录
  {
    path: "/teaevaluateRecord",
    name: "teaevaluateRecord",
    component: () => import("../views/mobile/teacher/Recordindex.vue"),
  },
  // 老师评价记录详情
  {
    path: "/tearecordInfo",
    name: "tearecordInfo",
    component: () => import("../views/mobile/teacher/Recordinfo.vue"),
  },
  //  ipad页面
  // 登录
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/index.vue"),
  },
  // 选择项目
  {
    path: "/project",
    name: "project",
    component: () => import("../views/project/index.vue"),
  },
  // 选择模式
  {
    path: "/pattern",
    name: "pattern",
    component: () => import("../views/pattern/index.vue"),
  },
  // 测试录音
  {
    path: "/audio",
    name: "audio",
    component: () => import("../views/mobile/audio.vue"),
  },
  // 训练
  {
    path: "/train",
    name: "train",
    component: () => import("../views/train/index.vue"),
    children: [
      {
        path: "/judgeQues",
        name: "judgeQues",
        component: () => import("../views/train/judgeQues.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

export default router;
